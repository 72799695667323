import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UserGoalDTO } from 'src/app/dtos/UserGoalDTO';
import { GoalTypeLabel } from 'src/app/services/goalSheet/goal-sheet-enum';
import { SubgoalService } from 'src/app/services/subgoal/subgoal.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { AssignSubGoalComponent } from '../../assign-sub-goal/assign-sub-goal.component';
import { DeleteSubgoalComponent } from '../delete-subgoal/delete-subgoal.component';
@Component({
  selector: 'app-manage-subgoals',
  templateUrl: './manage-subgoals.component.html',
  styleUrls: ['./manage-subgoals.component.scss'],
})
export class ManageSubgoalsComponent implements OnInit {
  userid: any;
  goal = [];
  goalTypeMapping: any;
  usergoaldto: UserGoalDTO;
  username;
  constructor(
    public subgoalservice: SubgoalService,
    public usergoalservice: UserGoalService,
    public userService: UserService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ManageSubgoalsComponent>,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public usergoalid: any
  ) {}
  ngOnInit(): void {
    this.subgoalservice.listen().subscribe((m: any) => {
      this.ngOnInit();
    });
    this.userid = this.userDetailService.getUserDetails().id;
    this.goalTypeMapping = GoalTypeLabel;
    this.getgoal();
    this.getsubgoal();
  }
  getgoal() {
    this.usergoalservice.getGoalByID(this.usergoalid.id).subscribe((res) => {
      this.usergoaldto = res.body;
      this.userService
        .getUserByID(this.usergoaldto.userId)
        .subscribe((response) => {
          this.username =
            response.body.firstName + ' ' + response.body.lastName;
        });
    });
  }
  getsubgoal() {
    let g = [];
    this.subgoalservice
      .getSubgoalByUserGoal(this.usergoalid.id)
      .subscribe((res) => {
        for (let i of res.body) {
          g.push(i);
        }
      });
    this.goal = g;
  }
  closeDialog() {
    this.dialogRef.close();
    this.usergoalservice.filter('Registerd Click');
  }
  openDialogueSubGoal(usergoalid, subgoal) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '50%px';
    let arr = [usergoalid, subgoal];
    dialogConfig.data = arr;
    this.dialog.open(AssignSubGoalComponent, dialogConfig);
  }
  openDeleteSubGoal(goalForDelete) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '320px';
    dialogConfig.height = '490px';
    dialogConfig.data = goalForDelete;
    const dialogRef = this.dialog.open(DeleteSubgoalComponent, dialogConfig).afterClosed().subscribe((result) => {
      this.getsubgoal();
    });
  }
}
