<div>
    <div *ngIf="isSpinner" class="spinner-div">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <label class="loading_label">Loading...</label>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3 search-bar mt-3">
          <img class="col-md-2 icon" src="\assets\svg\search_bar.svg" alt="search" />
          <input type="search" class="search-input col-md-10" placeholder="Search "
            />
        </div>
        <div class="col-md-1 btn mt-3">
          <div class="row">
            <div class="col-md-6 centered-content" [matTooltipPosition]="position.value" (click)="addBulkAttendanceRecords()"
              [matTooltipClass]="position.value" matTooltip="Bulk Import">
              <img class="img-class-bulk" src="assets/svg/bulk-import.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <app-under-construction></app-under-construction>
    