import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfficeAttendanceService {

  constructor(private restApiService: RestAPIService) {
   }

   getRecordsByDate(date: string){
       return this.restApiService.get(`/api/attendance-records/${date}`).pipe(
        map(res => res.body)
      );
     }

    uploadBulkAttendance(formData, isReuploading){
      if(!isReuploading){
        return this.restApiService.post('/api/attendance-records', formData);
      }else{
        return this.restApiService.put('/api/attendance-records/re-upload', formData);
      } 
     }
}
