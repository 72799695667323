import { Component, OnInit } from '@angular/core';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProfileDetailComponent } from '../profile-detail/profile-detail.component';
import { GeoLocation } from 'src/app/services/user/user-enum';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-calibrated-report',
  templateUrl: './calibrated-report.component.html',
  styleUrls: ['./calibrated-report.component.scss'],
})
export class CalibratedReportComponent implements OnInit {
  selected: number;
  nodata: Boolean = true;
  years = [];
  userDtao = [];
  isAll: Boolean = true;
  isOpen: Boolean = false;
  isClose: Boolean = false;
  selectedHrLocation = '';
  locationArray = [];
  searchText: string;
  loginUserInfo;
  isSuperAdmin: Boolean = false;
  data = [];
  isLocationDropDownTouched: Boolean = false;
  loggedInUserLocation: string;

  constructor(
    private feedbackgroupservice: NewFeedbackGroupService,
    private userDetailService: UserDetailsService,
    private dialogue: MatDialog,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = this.userDetailService.hasRole(['ROLE_SUPER_ADMIN']);
    this.loginUserInfo = this.userDetailService.getUserDetails();
    this.loggedInUserLocation = this.loginUserInfo.geoLocation;
    this.selectedHrLocation =
      this.loginUserInfo.geoLocation != null
        ? this.loginUserInfo.geoLocation
        : '';
    if (this.isSuperAdmin && this.selectedHrLocation == '') {
      this.selectedHrLocation = 'INDIA';
    }
    this.getCalendarNewDate();
    this.getAllEmployeeForCalibratedScore();
    this.updateLocationArray();
    this.applyDefaultFilter();
  }

  applyDefaultFilter() {
    this.isLocationDropDownTouched = true; // Ensure filter is applied
    if (this.isAll) {
      this.All();
    } else if (this.isOpen) {
      this.Open();
    } else {
      this.Close();
    }
  }

  updateLocationArray() {
    if (this.isSuperAdmin) {
      this.locationArray = Object.keys(GeoLocation);
    } else {
      if (this.loggedInUserLocation === 'INDIA') {
        this.locationArray.push('INDIA', 'AUSTRALIA');
      } else if (this.loggedInUserLocation === 'USA') {
        this.locationArray.push('USA');
      } else if (this.loggedInUserLocation === 'AUSTRALIA') {
        this.locationArray.push('AUSTRALIA');
      }
    }
  }

  getCalendarNewDate() {
    this.selected = new Date().getFullYear();
    for (let year = this.selected; year >= 2022; year--) {
      this.years.push(year);
    }
  }

  onViewReportee(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '95%';
    dialogConfig.maxWidth = '95%';
    dialogConfig.data = {
      userId: id,
      viewer: 'HR',
    };
    const dialogRef = this.dialogue.open(ProfileDetailComponent, dialogConfig);
  }

  getAllEmployeeForCalibratedScore() {
    if (this.loggedInUserLocation) {
      this.feedbackgroupservice
        .getdirectreporteebyyearforHr(this.selected)
        .subscribe((res) => {
          this.data = res.body;
          this.userDtao = res.body;
          // Apply filter after data is loaded
          this.applyDefaultFilter();
          if (res.body.length != 0) {
            this.nodata = false;
          }
          if (this.isAll == true) {
            this.All();
          } else if (this.isOpen == true) {
            this.Open();
          } else {
            this.Close();
          }
        });
    } else {
      this.toastService.warning(
        'Your geo-location is not configured, please contact the administrator.'
      );
    }
  }

  downloadExcel() {
    const XLSX = require('xlsx');

    const formattedData = this.userDtao.map((item) => ({
      'Employee Name': `${item.firstName} ${item.lastName}`,
      'Feedback Overall Score': item.totalScore,
      'Employee ID': item.employeeId,
      'Job Title': item.jobTitle,
      'Feedback Status': item.closed ? 'Closed' : 'Open',
      'Geo Location': item.userLocation,
      Capability: item.capability.replaceAll('_', ' '),
      'Career Manager': item.careerManager,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Feedback Data');

    XLSX.writeFile(
      workbook,
      'FeedbackData-' + this.selected + `-${this.selectedHrLocation}` + '.xlsx'
    );
  }

  changeYear(e: any) {
    this.selected = e.value;
    this.getAllEmployeeForCalibratedScore();
  }

  Open() {
    this.userDtao = this.data.filter((obj) => {
      return (
        (this.selectedHrLocation.toString() == obj.userLocation.toString() ||
          !this.isLocationDropDownTouched) &&
        !obj.closed
      );
    });
    this.isOpen = true;
    this.isClose = false;
    this.isAll = false;
  }

  Close() {
    this.userDtao = this.data.filter((obj) => {
      return (
        (this.selectedHrLocation.toString() == obj.userLocation.toString() ||
          !this.isLocationDropDownTouched) &&
        obj.closed
      );
    });
    this.isOpen = false;
    this.isClose = true;
    this.isAll = false;
  }

  All() {
    this.userDtao = this.data.filter((obj) => {
      return (
        this.selectedHrLocation.toString() == obj.userLocation.toString() ||
        !this.isLocationDropDownTouched
      );
    });
    this.isOpen = false;
    this.isClose = false;
    this.isAll = true;
  }

  changeLocation(e) {
    this.isLocationDropDownTouched = true;
    this.selectedHrLocation = e.value.toUpperCase();
    if (this.isOpen == true) {
      this.userDtao = this.data.filter((obj) => {
        return e.value.toString() == obj.userLocation.toString() && !obj.closed;
      });
    } else if (this.isClose == true) {
      this.userDtao = this.data.filter((obj) => {
        return e.value.toString() == obj.userLocation.toString() && obj.closed;
      });
    } else {
      this.userDtao = this.data.filter((obj) => {
        return e.value.toString() == obj.userLocation.toString();
      });
    }
  }

  getValueByKey(key: string): string | undefined {
    const enumValues = Object.values(GeoLocation) as string[];
    const enumKeys = Object.keys(GeoLocation);
    const index = enumKeys.indexOf(key);

    if (index !== -1) {
      return enumValues[index];
    }

    return undefined;
  }
}
