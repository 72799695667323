<div class="home-container mobile">
  <div class="row">
    <div class="col-lg-4">
      <div class="row">
        <div class="col-md-12" @slideUp><app-tasks></app-tasks></div>
      </div>
      <div class="row" *ngIf="hasCmRights">
        <div class="col-md-12" @slideUp><app-reportees-leave></app-reportees-leave></div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="row">
        <div class="col-md-12" *ngIf="hasBaseLocationPune" @slideUp>
          <app-office-attendance></app-office-attendance>
        </div>
        <div class="col-md-6" *ngIf="!hasBaseLocationPune" @slideUp>
          <app-daily-attendance></app-daily-attendance>
        </div>
        <div class="col-md-6" *ngIf="!hasBaseLocationPune" @slideUp>
          <app-working-from-office></app-working-from-office>
        </div>  
      </div>
      <div class="row">
        <div class="col-md-6" @slideUp><app-leave-summary></app-leave-summary></div>
        <div class="col-md-6" @slideUp>
          <app-upcoming-holidays></app-upcoming-holidays>
        </div>
        
      </div>
      <div class="row">
        <div class="col-md-6" @slideUp><app-birthdays-event></app-birthdays-event></div>
        <div class="col-md-6" @slideUp>
          <app-anniversary-event></app-anniversary-event>
        </div>
      </div> 
      </div>
  </div>
</div>

