import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { BulkEmployeeComponent } from 'src/app/components/bulk-employee-import/bulk-employee/bulk-employee.component';
import { OfficeAttendanceImportDialogComponent } from '../../office-attendance-import-dialog/office-attendance-import-dialog.component';

@Component({
  selector: 'app-office-attendance-admin',
  templateUrl: './office-attendance-admin.component.html',
  styleUrls: ['./office-attendance-admin.component.scss']
})
export class OfficeAttendanceAdminComponent implements OnInit {

  isSpinner: boolean;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }


   addBulkAttendanceRecords(){
  
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '75%';
      dialogConfig.height = '75%';
  
      const dialogRef = this.dialog.open(OfficeAttendanceImportDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {
      });
  
    }


}
